<template>
  <div>
    <div
      v-if="isFetching"
      style="display: flex; align-items: center; justify-content: center;"
    >
      <Spin fix>
        <Icon
          type="ios-loading"
          size="18"
          class="demo-spin-icon-load"
        />
        <div>{{ $t('global.loading') }}</div>
      </Spin>
    </div>
    <div v-else>
      <div v-if="mode === 'DOMAIN'">
        <Checkbox @on-change="handleGlobalNodesCheckChange($event)">
          {{ $t('global.filters.all') }}
        </Checkbox>
        <Button @click="fetch()">
          {{ $t('global.vendor.default_tree') }}
        </Button>
        <Divider />
      </div>
      <ul v-if="tree.length">
        <li
          style="cursor: pointer;"
          v-for="(node, index) in tree"
          :key="'tree-'+ node.id"

          @mouseenter="handleNodeMouseenter(node)"
          @mouseleave="handleNodeMouseleave(node)"
        >
          <hr
            style="border: 1px solid #E7E7E7"
            v-if="index && node.level === 1"
          >

          <Checkbox
            @on-change="handleNodeCheckChange(node, $event)"
            v-model="node.hasVendor"
          />

          <span :style="{'padding-left': (node.level) +'rem', 'border-left': '1px solid lightgray'}">
            <span
              v-if="mode === 'DOMAIN' && node.toBeAffected"
              style="color: #ca5872; font-weight: 500;"
            >
              {{ node.title }} <span style="font-size: 0.8em">({{ $t('global.vendor.will_b_auto_affect') }})</span>
            </span>
            <span v-else>{{ node.title }}</span>
            <a
              href="#"
              v-if="node.vendor"
            >
              <strong v-if="node.vendor.id === vendorId">{{ node.vendor.name }}</strong>
              <span v-else>{{ node.vendor.name }}</span>
            </a>
          </span>
        </li>
      </ul>
      <div v-else>
        {{ $t('global.domain.no_domains') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DomainsTree',
  props: {
    mode: {
      type: String,
      require: true,
      default: '',
      note: 'vendor or null'
    },
    vendorId: {
      type: Number,
      default: 0,
      note: 'the vendorId'
    },
    domain: {
      type: Object,
      default: () => {},
    }
  },
  data () {
    return {
      isFetching: true,
      vendor_id: this.vendorId,
      items: [],
      tree: []
    }
  },
  methods: {
    fetch () {
      this.isFetching = true
      let params = {}
      switch (this.mode) {
      case 'VENDOR':
        params = {
          vendor_id: this.vendor_id
        }
        break
      case 'DOMAIN':
        params = {
          reverse: this.domain.reverse
        }
        break
      default:
        break
      }
      return this.$axios('api/vendordomains', {
        cache: {
          exclude: {query: true}
        },
        params
      })
        .then(response => {
          if (response.data.success) {
            this.items = response.data.data
            this.buildTree(this.items)
            this.isFetching = false
          }
        })
    },
    buildTree (items) {
      if (!items) {
        throw new Error('No items...')
      }

      // Build tree domains hierarchy
      for (let lvl = 10; lvl > 0; lvl--) {
        for (const item of items) {
          if (item.level === lvl) {
            item.hasVendor = item.hasVendor || (item.vendor_id && (item.vendor_id === this.vendorId))

            // If domain not attached to a vendor and the "next" vendor is selected
            // then auto-check it for cascade affectation
            if (this.mode === 'DOMAIN' && (!item.vendor_id && this.vendorId)) {
              item.vendor_id = this.vendorId
              item.hasVendor = true
              item.toBeAffected = true
            }

            let parent = items.find(p => {
              return ((p.level === (item.level - 1)) && p.sld === item.sld &&
                    item.reverse.startsWith(p.reverse + '.'))
            })

            parent || (item.title = item.domain_pattern.replace(/\\/gi, '').replace('$', ''))
            parent && (item.title = item.reverse.replace(parent.reverse + '.', ''))
          }
        }
      }

      this.tree = items
    },
    handleNodeMouseenter (node) {
      this.$set(node, 'highlight', true)
    },
    handleNodeMouseleave (node) {
      this.tree.forEach(e => {
        this.$set(e, 'highlight', false)
      })
    },
    handleGlobalNodesCheckChange (status) {
      this.tree = this.tree.map(node => {
        node.hasVendor = status

        return node
      })
    },
    handleNodeCheckChange (node, isChecked) {
      // Find node in tree
      const nodeIndex = this.tree.findIndex(n => n.id === node.id)

      // If node found, update property in-place
      if (nodeIndex !== -1) {
        this.tree[nodeIndex].hasVendor = isChecked
      }
    },
    async saveTree (override = false, overrideVendorId = null) {
      const nextVendorId = override ? overrideVendorId : this.vendorId

      // vendorId (could be provided by parent component)
      // Buffer of requests to send
      let requests = []

      // Add request to the buffer
      const addRequest = (node, vendorId) => {
        requests = [
          ...requests,
          this.$axios.put('api/vendordomains/' + node.id, {
            vendor_id: vendorId
          })
        ]
      }

      console.log('tree nodes', this.tree)
      // For each tree in the node:
      this.tree.forEach(node => {
        switch (this.mode) {
        case 'DOMAIN': {
          // If the current node is checked, override its vendor
          if (node.hasVendor) {
            addRequest(node, nextVendorId)
          } else {
            // If its not checked and the current vendor_id is the same
            // as the selected vendor Id, then just remove the vendor (unassigned)
            if (node.vendor_id === nextVendorId) {
              addRequest(node, null)
            }
          }

          break
        }
        case 'VENDOR': {
          // If checked
          if (node.hasVendor) {
            // If current node vendor id is different than the edited vendor
            if (node.vendor_id !== nextVendorId) {
              addRequest(node, nextVendorId)
            }
            // If not checked
          } else {
            // If current node vendor id is equal to the vendor being edited (uncheck)
            if (node.vendor_id === nextVendorId) {
              addRequest(node, null)
            }
          }

          break
        }
        }
      })

      // Send all requests
      const [...responses] = await Promise.all(requests)

      console.log(responses)

      await this.fetch()
    }
  },
  mounted () {
    this.vendor_id = this.vendorId || null
    this.fetch()
  }
}
</script>

<style scoped>

</style>
