<template>
  <Form
    class="stacked-form"
    ref="form"
    :model="vendor"
    :rules="formRules"
  >
    <Spin size="large"
          fix
          v-if="isLoading"
    />
    <div class="tile-block">
      <!-- <Button type="primary"
              class="btn btn--elevated"
              @click="saveVendor">
        Save
      </Button> -->
      <Row
        :gutter="24"
        align="top"
      >
        <Col :span="create ? '24' : '12'">
          <FormItem
            prop="name"
            :label="$t('global.table.name')"
          >
            <Input
              type="text"
              :placeholder="$t('global.vendor.enter_name')"
              v-model="vendor.name"
            />
          </FormItem>
          <FormItem
            prop="country"
            :label="$t('global.table.country')"
            v-if="Object.keys(options).length"
          >
            <Select
              v-model="vendor.country_code"
              filterable
            >
              <Option
                v-for="country in options.countries"
                :value="country.code"
                :key="country.code"
              >
                {{ country.name }}
              </Option>
            </Select>
          </FormItem>
          <FormItem
            prop="category"
            :label="$t('global.table.category')"
            v-if="Object.keys(options).length"
          >
            <Select
              v-model="vendor.vendorcategory_id"
              @on-change="elm => defineExemption(elm)"
              filterable
            >
              <Option
                v-for="category in options.categories"
                :value="category.id"
                :label="category.name"
                :key="category.id"
              >
                <span>{{ category.name }}</span>
                <!-- <span style="float:right;color:#ccc">America</span> -->
                <Tag style="margin-left:15px; background:#FFBD4133!important"
                     v-if="category.is_exempted"
                >
                  exempted
                </Tag>
              </Option>
            </Select>
          </FormItem>
          <template v-if="vendor.vendorcategory_id !== 0">
            <Alert type="warning"
                   show-icon
            > 
              The category exemption will be propagated to the vendor, you can change it manually
            </Alert>
            <FormItem>
              <span slot="label">Is the vendor exempted</span>
              <Checkbox v-if="vendor.vendorcategory_id !== 0"
                        v-model="vendor.is_exempted"
              >
                <span style="margin-left:7px">{{ vendor.is_exempted ? 'Yes' : 'No' }}</span>
              </Checkbox>
            </FormItem>
          </template>
        </Col>
        <Col
          span="12"
          v-if="!create"
        >
          <FormItem
            prop="aliases"
            :label="$t('global.vendor.aliases')"
            class="aliases-input"
          >
            <Input
              type="text"
              :placeholder="$t('global.vendor.add_aliases')"
              v-model="aliasInput"
              @on-keypress="addNewAlias"
              ref="aliasInput"
            />
            <Tag
              v-for="alias in vendor.vendoraliases"
              :key="alias.id"
              :name="alias.id"
              closable
              @on-close="deleteAlias"
            >
              {{ alias.name }}
            </Tag>
          </FormItem>
        </Col>
        <Col
          span="24"
          v-if="create"
        >
          <FormItem
            prop="description"
            :label="$t('global.description')"
          >
            <Input
              type="textarea"
              :rows="5"
              :placeholder="$t('global.vendor.add_description')"
              v-model="vendor.description"
            />
          </FormItem>
        </Col>
        <Col span="12">
          <FormItem
            v-if="!create"
            prop="description"
            :label="$t('global.description')"
          >
            <Input
              type="textarea"
              :rows="5"
              :placeholder="$t('global.vendor.add_description')"
              v-model="vendor.description"
            />
          </FormItem>
          <FormItem
            prop="websiteContactEmail"
            :label="$t('global.vendor.contact')"
          >
            <Input
              type="email"
              :placeholder="$t('global.vendor.add_contact')"
              v-model="vendor.websiteContactEmail"
            />
          </FormItem>
          <FormItem
            prop="email"
            :label="$t('global.vendor.contact_l')"
          >
            <Input
              type="email"
              :placeholder="$t('global.vendor.add_contact_l')"
              v-model="vendor.email"
            />
          </FormItem>
          <FormItem
            prop="email"
            :label="$t('global.vendor.privacy')"
          >
            <Input
              type="email"
              :placeholder="$t('global.vendor.privacy')"
              v-model="vendor.privacyPolicyUrl"
            />
          </FormItem>
          <FormItem
            prop="website"
            :label="$t('global.vendor.url')"
          >
            <Input
              type="url"
              :placeholder="$t('global.vendor.add_url')"
              v-model="vendor.website"
            />
          </FormItem>
          <template v-if="!create">
            <FormItem
              prop="location"
              :label="$t('global.vendor.location')"
            >
              <Input
                type="text"
                :placeholder="$t('global.vendor.add_location')"
                v-model="vendor.location"
              />
            </FormItem>
            <FormItem
              prop="foundedAt"
              :label="$t('global.vendor.founded_at')"
            >
              <Input
                type="text"
                :placeholder="$t('global.vendor.add_founded')"
                v-model="vendor.foundedAt"
              />
            </FormItem>
            <FormItem
              prop="iabv2Id"
              :label="$t('global.vendor.tcf2')"
            >
              <Input
                type="text"
                v-model="vendor.iabv2Id"
                :disabled="!!inputVendor.lock_iabv2Id"
              >
                <span
                  v-if="inputVendor.lock_iabv2Id"
                  slot="prepend"
                >
                  {{ $t('global.vendor.lock_iabv2Id_description') }}
                </span>
              </Input>
            </FormItem>
            <FormItem
              prop="lock_iabv2Id"
              :label="$t('global.table.lock_iabv2Id')"
            >
              <span
                v-if="inputVendor.lock_iabv2Id"
                class="chips chips--success"
              >
                {{ $t('global.property.enable') }}
              </span>
              <span
                v-else
                class="chips chips--red"
              >
                {{ $t('global.property.disable') }}
              </span>
            </FormItem>
          </template>
        </Col>
        <Col span="12">
          <template v-if="create">
            <FormItem
              prop="location"
              :label="$t('global.vendor.location')"
            >
              <Input
                type="text"
                :placeholder="$t('global.vendor.add_location')"
                v-model="vendor.location"
              />
            </FormItem>
            <FormItem
              prop="foundedAt"
              :label="$t('global.vendor.founded_at')"
            >
              <Input
                type="text"
                :placeholder="$t('global.vendor.add_founded')"
                v-model="vendor.foundedAt"
              />
            </FormItem>
            <FormItem
              prop="iabv2Id"
              :label="$t('global.vendor.tcf2')"
            >
              <Input
                type="text"
                v-model="vendor.iabv2Id"
              />
            </FormItem>
            <FormItem
              prop="lock_iabv2Id"
              :label="$t('global.table.lock_iabv2Id')"
            >
              <i-switch
                v-model="vendor.lock_iabv2Id"
                true-value="1"
                false-value="0"
              />
            </FormItem>
          </template>
          <FormItem
            v-else
            :label="$t('global.vendor.check_domains_link')"
          >
            <br>
            <DomainsTree
              ref="domainsTreeComponent"
              mode="VENDOR"
              :vendor-id="inputVendor.id"
            />
          </FormItem>
          <!-- <FormItem prop="image" label="Image logo">
            <Upload type="drag"
                    :before-upload="loadImagePreview"
                    action="/">
                <Col span="24">
                  <h4>Open a file</h4>
                </Col>
                <Col span="24" v-if="this.imagePreview">
                  <img :src="this.imagePreview" alt="uploaded image" :width="100" :height="100">
                </Col>
            </Upload>
          </FormItem> -->
        </Col>
      </Row>
      <Row
        type="flex"
        align="top"
        justify="end"
      >
        <Button
          type="primary"
          class="btn btn--elevated"
          style="text-align:right"
          @click="create ? createVendor() : saveVendor()"
        >
          {{ this.create ? $t('global.create') : $t('global.save') }}
        </Button>
      </Row>
    </div>
  </Form>
</template>

<script>
import { mapActions } from 'vuex'
import DomainsTree from '@/views/Vendors/FicheVendor/DomainsTree'

export default {
  name: 'VendorEditor',
  components: {DomainsTree},
  props: {
    inputVendor: {
      type: Object,
      default: () => {},
      note: 'Vendor data needed'
    },
    create: {
      type: Boolean,
      default: false,
      note: 'If its a creation'
    }
  },
  data () {
    return {
      options: {
        categories: []
      },
      isLoading: true,
      vendor: {
        vendoraliases: [],
        vendorcategory_id: 0,
        privacyPolicyUrl: ''
      },
      aliasInput: '',
      imagePreview: '',
      formRules: {
        name: [
          {
            required: true,
            message: this.$t('global.validation.add_aliases')
          },
          {
            type: 'string',
            min: 2,
            whitespace: true,
            message: this.$t('global.validation.char_min2')
          }
        ],
        iabv2Id: [
          {
            type: 'string',
            message: this.$t('global.validation.num'),
            trigger: 'blur',
            validator: (_, value) => value === undefined || !isNaN(value)
          }
        ],
        country: [
          {
            required: true,
            message: this.$t('global.validation.country'),
            validator: () => !!this.vendor.country_code && this.vendor.country_code.length > 0
          }
        ],
        category: [
          {
            required: true,
            message: this.$t('global.validation.category'),
            validator: () => !!this.vendor.vendorcategory_id && this.vendor.vendorcategory_id !== null
          }
        ],
        website: [
          {
            type: 'string',
            message: this.$t('global.validation.url'),
            trigger: 'blur',
            validator: (_, value) => value === undefined || /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]*(:[0-9]{1,5})?(\/.*)?$/.test(value)
          }
        ],
        websiteContactEmail: [
          {
            type: 'email',
            message: this.$t('global.validation.email'),
            trigger: 'change'
          }
        ],
        email: [
          {
            type: 'email',
            message: this.$t('global.validation.email'),
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions({
      vendorGetVendorById: 'vendor/getVendorById',
      vendorEditVendorById: 'vendor/editVendorById',
      vendorGetAllOptions: 'vendor/getAllOptions',
      vendorAddVendorAliases: 'vendor/addVendorAliases',
      vendorDeleteVendorAliases: 'vendor/deleteVendorAliases',
      getVendorAliases: 'vendor/getVendorAliases',
      actionCreateVendor: 'vendor/createVendor'
    }),
    defineExemption (catId) {
      const { options, vendor } = this
      const exemption = options.categories.find(cat => cat.id === catId).is_exempted
      vendor['is_exempted'] = !!exemption
    },
    addNewAlias (event) {
      const theVendorId = this.vendor.id
      if (event.code === 'Enter') {
        this.vendorAddVendorAliases({
          aliasNames: [this.aliasInput],
          vendorId: theVendorId
        }).then(resp => {
          return this.getVendorAliases({vendor_id: theVendorId})
            .then(resp => {
              resp.data.forEach(alias => {
                if (!this.vendor.vendoraliases.find(a => alias.id === a.id)) {
                  this.vendor.vendoraliases.push(alias)
                }
              })
              this.$Notice.success({title: 'Aliase ' + this.aliasInput.toUpperCase() + ' added'})
              this.aliasInput = ''
            })
        }).catch(er => {
          this.$Notice.warning({title: 'A validation error occured'})
        })
      }
    },
    deleteAlias (_, id) {
      this.isLoading = true
      return this.vendorDeleteVendorAliases([id])
        .then(() => {
          this.isLoading = false
          this.$Notice.success({title: 'Aliase ' + this.vendor.vendoraliases.find(v => v.id === id).name + ' removed'})
          this.vendor.vendoraliases.splice(this.vendor.vendoraliases.findIndex(v => v.id === id), 1)
        })
    },
    createVendor () {
      this.isLoading = true
      const data = this.vendor
      data.name = data.name.trim()
      if (this.vendor.is_exempted === true) data.is_exempted = 1
      if (this.vendor.is_exempted === false) data.is_exempted = 0
      this.$refs['form'].validate((valid) => {
        if (valid) {
          return this.actionCreateVendor(data)
            .then(resp => {
              this.isLoading = false
              this.$Notice.success({title: 'Vendor has been created'})
              if (resp.data.uuid) {
                return this.$router.push('/vendors/' + resp.data.uuid)
              }
            })
            .catch(er => {
              this.isLoading = false
              this.$Notice.warning({ title: 'A validation error occured' })
            })
        } else {
          this.isLoading = false
          this.$Message.error('Some form entries are not good')
        }
      })
    },
    saveVendor () {
      this.isLoading = true
      let updatedFields = {}
      const data = JSON.parse(JSON.stringify(this.vendor))
      if (this.vendor.is_exempted === true) data.is_exempted = 1
      if (this.vendor.is_exempted === false) data.is_exempted = 0
      for (const [key, value] of Object.entries(data)) {
        if (this.inputVendor[key] !== value) {
          updatedFields[key] = value
        }
      }
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$refs.domainsTreeComponent.saveTree()
          this.vendorEditVendorById({id: this.vendor.id, vendor: updatedFields}).then(res => {
            this.isLoading = false
            if (res.success) {
              this.$emit('refresh', true)
              this.$Notice.success({
                title: 'Vendor successfully saved'
              })
            }
          }).catch(er => {
            this.isLoading = false
            this.$Message.error('A server error occured')
          })
        } else {
          this.isLoading = false
          this.$Message.error('Some form entries are not good')
        }
      })
    },
    loadImagePreview (file) {
      const url = URL.createObjectURL(file)

      console.log('IMG url:', url)
      this.imagePreview = url
    }
  },
  mounted () {
    this.isLoading = true
    this.vendorGetAllOptions()
      .then(r => {
        this.options = r
        this.isLoading = false
      })
      .catch(er => {
        this.isLoading = false
        this.$Message.error('Error Fetching vendorsOptions, please contact administrator')
        this.$route.name === 'Vendors admin add' && this.$router.push('/admin/vendors')
      })

    if (this.create) return 0
    this.vendor = Object.assign({}, this.inputVendor)
    this.vendor.vendoraliases.sort((a, b) => {
      if (a.name < b.name) { return -1 }
      if (a.name > b.name) { return 1 }
      return 0
    })
  }
}
</script>

<style>
.ivu-upload-drag {
  width: 100%;
}
</style>
