var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Form', {
    ref: "form",
    staticClass: "stacked-form",
    attrs: {
      "model": _vm.vendor,
      "rules": _vm.formRules
    }
  }, [_vm.isLoading ? _c('Spin', {
    attrs: {
      "size": "large",
      "fix": ""
    }
  }) : _vm._e(), _c('div', {
    staticClass: "tile-block"
  }, [_c('Row', {
    attrs: {
      "gutter": 24,
      "align": "top"
    }
  }, [_c('Col', {
    attrs: {
      "span": _vm.create ? '24' : '12'
    }
  }, [_c('FormItem', {
    attrs: {
      "prop": "name",
      "label": _vm.$t('global.table.name')
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('global.vendor.enter_name')
    },
    model: {
      value: _vm.vendor.name,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "name", $$v);
      },
      expression: "vendor.name"
    }
  })], 1), Object.keys(_vm.options).length ? _c('FormItem', {
    attrs: {
      "prop": "country",
      "label": _vm.$t('global.table.country')
    }
  }, [_c('Select', {
    attrs: {
      "filterable": ""
    },
    model: {
      value: _vm.vendor.country_code,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "country_code", $$v);
      },
      expression: "vendor.country_code"
    }
  }, _vm._l(_vm.options.countries, function (country) {
    return _c('Option', {
      key: country.code,
      attrs: {
        "value": country.code
      }
    }, [_vm._v(" " + _vm._s(country.name) + " ")]);
  }), 1)], 1) : _vm._e(), Object.keys(_vm.options).length ? _c('FormItem', {
    attrs: {
      "prop": "category",
      "label": _vm.$t('global.table.category')
    }
  }, [_c('Select', {
    attrs: {
      "filterable": ""
    },
    on: {
      "on-change": function onChange(elm) {
        return _vm.defineExemption(elm);
      }
    },
    model: {
      value: _vm.vendor.vendorcategory_id,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "vendorcategory_id", $$v);
      },
      expression: "vendor.vendorcategory_id"
    }
  }, _vm._l(_vm.options.categories, function (category) {
    return _c('Option', {
      key: category.id,
      attrs: {
        "value": category.id,
        "label": category.name
      }
    }, [_c('span', [_vm._v(_vm._s(category.name))]), category.is_exempted ? _c('Tag', {
      staticStyle: {
        "margin-left": "15px",
        "background": "#FFBD4133!important"
      }
    }, [_vm._v(" exempted ")]) : _vm._e()], 1);
  }), 1)], 1) : _vm._e(), _vm.vendor.vendorcategory_id !== 0 ? [_c('Alert', {
    attrs: {
      "type": "warning",
      "show-icon": ""
    }
  }, [_vm._v(" The category exemption will be propagated to the vendor, you can change it manually ")]), _c('FormItem', [_c('span', {
    attrs: {
      "slot": "label"
    },
    slot: "label"
  }, [_vm._v("Is the vendor exempted")]), _vm.vendor.vendorcategory_id !== 0 ? _c('Checkbox', {
    model: {
      value: _vm.vendor.is_exempted,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "is_exempted", $$v);
      },
      expression: "vendor.is_exempted"
    }
  }, [_c('span', {
    staticStyle: {
      "margin-left": "7px"
    }
  }, [_vm._v(_vm._s(_vm.vendor.is_exempted ? 'Yes' : 'No'))])]) : _vm._e()], 1)] : _vm._e()], 2), !_vm.create ? _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_c('FormItem', {
    staticClass: "aliases-input",
    attrs: {
      "prop": "aliases",
      "label": _vm.$t('global.vendor.aliases')
    }
  }, [_c('Input', {
    ref: "aliasInput",
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('global.vendor.add_aliases')
    },
    on: {
      "on-keypress": _vm.addNewAlias
    },
    model: {
      value: _vm.aliasInput,
      callback: function callback($$v) {
        _vm.aliasInput = $$v;
      },
      expression: "aliasInput"
    }
  }), _vm._l(_vm.vendor.vendoraliases, function (alias) {
    return _c('Tag', {
      key: alias.id,
      attrs: {
        "name": alias.id,
        "closable": ""
      },
      on: {
        "on-close": _vm.deleteAlias
      }
    }, [_vm._v(" " + _vm._s(alias.name) + " ")]);
  })], 2)], 1) : _vm._e(), _vm.create ? _c('Col', {
    attrs: {
      "span": "24"
    }
  }, [_c('FormItem', {
    attrs: {
      "prop": "description",
      "label": _vm.$t('global.description')
    }
  }, [_c('Input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "placeholder": _vm.$t('global.vendor.add_description')
    },
    model: {
      value: _vm.vendor.description,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "description", $$v);
      },
      expression: "vendor.description"
    }
  })], 1)], 1) : _vm._e(), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [!_vm.create ? _c('FormItem', {
    attrs: {
      "prop": "description",
      "label": _vm.$t('global.description')
    }
  }, [_c('Input', {
    attrs: {
      "type": "textarea",
      "rows": 5,
      "placeholder": _vm.$t('global.vendor.add_description')
    },
    model: {
      value: _vm.vendor.description,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "description", $$v);
      },
      expression: "vendor.description"
    }
  })], 1) : _vm._e(), _c('FormItem', {
    attrs: {
      "prop": "websiteContactEmail",
      "label": _vm.$t('global.vendor.contact')
    }
  }, [_c('Input', {
    attrs: {
      "type": "email",
      "placeholder": _vm.$t('global.vendor.add_contact')
    },
    model: {
      value: _vm.vendor.websiteContactEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "websiteContactEmail", $$v);
      },
      expression: "vendor.websiteContactEmail"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "email",
      "label": _vm.$t('global.vendor.contact_l')
    }
  }, [_c('Input', {
    attrs: {
      "type": "email",
      "placeholder": _vm.$t('global.vendor.add_contact_l')
    },
    model: {
      value: _vm.vendor.email,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "email", $$v);
      },
      expression: "vendor.email"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "email",
      "label": _vm.$t('global.vendor.privacy')
    }
  }, [_c('Input', {
    attrs: {
      "type": "email",
      "placeholder": _vm.$t('global.vendor.privacy')
    },
    model: {
      value: _vm.vendor.privacyPolicyUrl,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "privacyPolicyUrl", $$v);
      },
      expression: "vendor.privacyPolicyUrl"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "website",
      "label": _vm.$t('global.vendor.url')
    }
  }, [_c('Input', {
    attrs: {
      "type": "url",
      "placeholder": _vm.$t('global.vendor.add_url')
    },
    model: {
      value: _vm.vendor.website,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "website", $$v);
      },
      expression: "vendor.website"
    }
  })], 1), !_vm.create ? [_c('FormItem', {
    attrs: {
      "prop": "location",
      "label": _vm.$t('global.vendor.location')
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('global.vendor.add_location')
    },
    model: {
      value: _vm.vendor.location,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "location", $$v);
      },
      expression: "vendor.location"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "foundedAt",
      "label": _vm.$t('global.vendor.founded_at')
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('global.vendor.add_founded')
    },
    model: {
      value: _vm.vendor.foundedAt,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "foundedAt", $$v);
      },
      expression: "vendor.foundedAt"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "iabv2Id",
      "label": _vm.$t('global.vendor.tcf2')
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "disabled": !!_vm.inputVendor.lock_iabv2Id
    },
    model: {
      value: _vm.vendor.iabv2Id,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "iabv2Id", $$v);
      },
      expression: "vendor.iabv2Id"
    }
  }, [_vm.inputVendor.lock_iabv2Id ? _c('span', {
    attrs: {
      "slot": "prepend"
    },
    slot: "prepend"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.lock_iabv2Id_description')) + " ")]) : _vm._e()])], 1), _c('FormItem', {
    attrs: {
      "prop": "lock_iabv2Id",
      "label": _vm.$t('global.table.lock_iabv2Id')
    }
  }, [_vm.inputVendor.lock_iabv2Id ? _c('span', {
    staticClass: "chips chips--success"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.property.enable')) + " ")]) : _c('span', {
    staticClass: "chips chips--red"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.property.disable')) + " ")])])] : _vm._e()], 2), _c('Col', {
    attrs: {
      "span": "12"
    }
  }, [_vm.create ? [_c('FormItem', {
    attrs: {
      "prop": "location",
      "label": _vm.$t('global.vendor.location')
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('global.vendor.add_location')
    },
    model: {
      value: _vm.vendor.location,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "location", $$v);
      },
      expression: "vendor.location"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "foundedAt",
      "label": _vm.$t('global.vendor.founded_at')
    }
  }, [_c('Input', {
    attrs: {
      "type": "text",
      "placeholder": _vm.$t('global.vendor.add_founded')
    },
    model: {
      value: _vm.vendor.foundedAt,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "foundedAt", $$v);
      },
      expression: "vendor.foundedAt"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "iabv2Id",
      "label": _vm.$t('global.vendor.tcf2')
    }
  }, [_c('Input', {
    attrs: {
      "type": "text"
    },
    model: {
      value: _vm.vendor.iabv2Id,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "iabv2Id", $$v);
      },
      expression: "vendor.iabv2Id"
    }
  })], 1), _c('FormItem', {
    attrs: {
      "prop": "lock_iabv2Id",
      "label": _vm.$t('global.table.lock_iabv2Id')
    }
  }, [_c('i-switch', {
    attrs: {
      "true-value": "1",
      "false-value": "0"
    },
    model: {
      value: _vm.vendor.lock_iabv2Id,
      callback: function callback($$v) {
        _vm.$set(_vm.vendor, "lock_iabv2Id", $$v);
      },
      expression: "vendor.lock_iabv2Id"
    }
  })], 1)] : _c('FormItem', {
    attrs: {
      "label": _vm.$t('global.vendor.check_domains_link')
    }
  }, [_c('br'), _c('DomainsTree', {
    ref: "domainsTreeComponent",
    attrs: {
      "mode": "VENDOR",
      "vendor-id": _vm.inputVendor.id
    }
  })], 1)], 2)], 1), _c('Row', {
    attrs: {
      "type": "flex",
      "align": "top",
      "justify": "end"
    }
  }, [_c('Button', {
    staticClass: "btn btn--elevated",
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "type": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.create ? _vm.createVendor() : _vm.saveVendor();
      }
    }
  }, [_vm._v(" " + _vm._s(this.create ? _vm.$t('global.create') : _vm.$t('global.save')) + " ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }