var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isFetching ? _c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('Spin', {
    attrs: {
      "fix": ""
    }
  }, [_c('Icon', {
    staticClass: "demo-spin-icon-load",
    attrs: {
      "type": "ios-loading",
      "size": "18"
    }
  }), _c('div', [_vm._v(_vm._s(_vm.$t('global.loading')))])], 1)], 1) : _c('div', [_vm.mode === 'DOMAIN' ? _c('div', [_c('Checkbox', {
    on: {
      "on-change": function onChange($event) {
        return _vm.handleGlobalNodesCheckChange($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.filters.all')) + " ")]), _c('Button', {
    on: {
      "click": function click($event) {
        return _vm.fetch();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.vendor.default_tree')) + " ")]), _c('Divider')], 1) : _vm._e(), _vm.tree.length ? _c('ul', _vm._l(_vm.tree, function (node, index) {
    return _c('li', {
      key: 'tree-' + node.id,
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "mouseenter": function mouseenter($event) {
          return _vm.handleNodeMouseenter(node);
        },
        "mouseleave": function mouseleave($event) {
          return _vm.handleNodeMouseleave(node);
        }
      }
    }, [index && node.level === 1 ? _c('hr', {
      staticStyle: {
        "border": "1px solid #E7E7E7"
      }
    }) : _vm._e(), _c('Checkbox', {
      on: {
        "on-change": function onChange($event) {
          return _vm.handleNodeCheckChange(node, $event);
        }
      },
      model: {
        value: node.hasVendor,
        callback: function callback($$v) {
          _vm.$set(node, "hasVendor", $$v);
        },
        expression: "node.hasVendor"
      }
    }), _c('span', {
      style: {
        'padding-left': node.level + 'rem',
        'border-left': '1px solid lightgray'
      }
    }, [_vm.mode === 'DOMAIN' && node.toBeAffected ? _c('span', {
      staticStyle: {
        "color": "#ca5872",
        "font-weight": "500"
      }
    }, [_vm._v(" " + _vm._s(node.title) + " "), _c('span', {
      staticStyle: {
        "font-size": "0.8em"
      }
    }, [_vm._v("(" + _vm._s(_vm.$t('global.vendor.will_b_auto_affect')) + ")")])]) : _c('span', [_vm._v(_vm._s(node.title))]), node.vendor ? _c('a', {
      attrs: {
        "href": "#"
      }
    }, [node.vendor.id === _vm.vendorId ? _c('strong', [_vm._v(_vm._s(node.vendor.name))]) : _c('span', [_vm._v(_vm._s(node.vendor.name))])]) : _vm._e()])], 1);
  }), 0) : _c('div', [_vm._v(" " + _vm._s(_vm.$t('global.domain.no_domains')) + " ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }